<template>
  <v-data-table
    :headers="headers"
    :items="customersFiltered"
    class="elevation-1 h-100"
    :items-per-page="50"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-toolbar
        flat
      >
        <v-toolbar-title>Mis clientes</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar cliente..."
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
        <template>
          <div class="mb-2">
            <v-btn
              dark
              small
              class="mr-2"
              @click="importCustomers"
            >
              Importar clientes
            </v-btn>
            <v-btn
              dark
              small
              class="mr-2"
              @click="exportCustomers"
            >
              Exportar clientes
            </v-btn>
            <v-btn
              dark
              color=primary
              class=""
              @click="goToClientPage"
            >
              Agregar nuevo
            </v-btn>
          </div>
        </template>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Esta seguro que desea borrar este item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary darken-1" text @click="deleteItemConfirm">Confirmar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-dialog v-model="dialogImport" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Suba el archivo CSV que quiera importar</v-card-title>
          <v-card-text>
            <a href="https://gwinn.com.ar/API/assets/excels/clientsExample.csv" target="_blank">Descargar CSV ejemplo</a>
            <br>
            <br>
            <v-file-input
              counter
              show-size
              truncate-length="50"
              max-width="500px"
              v-model="importCSVFile"
            ></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn color="darken-1" text @click="closeImport">Cancelar</v-btn>
              <v-btn color="primary darken-1" text @click="confirmInport">Confirmar</v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-footer
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>

    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="getUsers"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { importUsers } from '../services/users'
export default {
  name: 'Customers',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogImport: false,
    importCSVFile: null,
    headers: [
      {
        text: 'Cliente',
        align: 'start',
        value: 'completeName'
      },
      {
        text: 'DNI/CUIT',
        value: 'dni'
      },
      {
        text: 'Tipo',
        value: 'userType.type'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Telefono',
        value: 'phone1'
      },
      {
        text: 'Acciones',
        value: 'actions',
        sortable: false
      }
    ],
    customers: [],
    customersFiltered: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      dni: '',
      tipo: '',
      email: '',
      phone1: ''
    },
    defaultItem: {
      name: '',
      dni: '',
      tipo: '',
      email: '',
      phone1: ''
    },
    search: ''
  }),

  computed: {
    ...mapGetters(['userToken']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    search () {
      this.searchCustomers()
    }
  },

  async created () {
    this.handleLoading(true)
    await this.getUsers()
    this.handleLoading(false)
  },

  methods: {
    ...mapActions(['storeCustomers', 'handleLoading']),
    goToClientPage () {
    // :to="{path: '/cliente'}"
      this.$router.push('/cliente')
    },
    async getUsers () {
      try {
        const res = await axios.get('https://gwinn.com.ar/API/v1/users.php', {
          headers: {
            Authorization: 'Bearer ' + this.userToken
          }
        })
        if (res.data.status === 200) {
          this.customers = res.data.data
          this.customers.forEach(c => {
            if (c.userType.id === 1) {
              c.completeName = c.lastname + ' ' + c.name
            } else {
              c.completeName = c.businessData.name
            }
          })
          this.storeCustomers(this.customers)
          this.searchCustomers()
        } else {
          this.responseLogin = res.data.response
        }
        return res
      } catch (err) {
        this.responseLogin = 'Surgio un error al intentar ingresar. Por favor intente mas tarde.'
        throw err.response
      }
    },

    searchCustomers () {
      this.customersFiltered = [...this.customers]
      if (this.search !== '') {
        this.customersFiltered = this.customersFiltered.filter(c =>
          c.name.toLowerCase().includes(this.search.toLowerCase()) ||
          c.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
          c.businessData?.name.toLowerCase().includes(this.search.toLowerCase()) ||
          c.dni.includes(this.search)
        )
      }
    },

    editItem (item) {
      this.$router.push({ path: '/cliente/' + item.id })
    },

    deleteItem (item) {
      this.editedIndex = this.customers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.closeDelete()
      const customerToDelete = this.editedItem
      this.handleLoading(true)
      try {
        const res = await axios.delete('https://gwinn.com.ar/API/v1/users.php', {
          headers: {
            Authorization: 'Bearer ' + this.userToken
          },
          data: {
            id: this.editedItem.id
          }
        })
        if (res.data.status === 200) {
          // alert(res.data.response)
          const index = this.customers.findIndex(u => u.id === customerToDelete.id)
          if (index !== -1) {
            this.customers.splice(index, 1)
            this.searchCustomers()
          }
          // this.customers.splice(this.editedIndex, 1)
          // this.searchCustomers()
        } else {
          if (res.data.status === 400) {
            this.$router.push('/login')
          } else {
            alert(res.data.response)
          }
        }
        return res
      } catch (err) {
        this.responseLogin = 'Surgio un error al intentar ingresar. Por favor intente mas tarde.'
        throw err.response
      } finally {
        this.handleLoading(false)
      }
    },

    async confirmInport () {
      if (this.importCSVFile.type !== 'text/csv') {
        alert('El archivo debe ser CSV')
      } else {
        const formData = new FormData()
        formData.append('csv-clients', this.importCSVFile)
        this.closeImport()
        this.handleLoading(true)
        try {
          const res = await importUsers(this.userToken, formData)
          console.log(res)
          await this.getUsers()
        } catch (error) {
          console.log(error)
        } finally {
          this.handleLoading(false)
        }
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeImport () {
      this.dialogImport = false
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.customers[this.editedIndex], this.editedItem)
      } else {
        this.customers.push(this.editedItem)
      }
      this.close()
    },

    exportCustomers () {
      window.open('https://gwinn.com.ar/API/v1/export-clients.php', '_blank')
    },

    importCustomers () {
      this.dialogImport = true
    }
  }
}
</script>
